import React, { useEffect } from 'react';

function FolderPicker({ token, onFolderPicked }) {
  useEffect(() => {
    // Load the Google Picker API
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.onload = () => {
        window.gapi.load('picker', () => {
          // The Picker API has been loaded, you can now create and render the picker.
        });
  
        // Load the Google Drive API
        window.gapi.load('client', () => {
          window.gapi.client.setApiKey(process.env.REACT_APP_FIREBASE_AND_GOOGLE_API_KEY);
          window.gapi.client.load('drive', 'v3', () => {
            console.log('Google Drive API loaded');
          });
        });
      };
    document.body.appendChild(script);
  }, []);

  const handlePickFolder = () => {
    // Load the Google Picker API and create the picker
    window.gapi.load('picker', () => {
    
      const view = new window.google.picker.DocsView(window.google.picker.ViewId.DOCS);
      view.setIncludeFolders(true);
      view.setSelectFolderEnabled(true);
  
      const picker = new window.google.picker.PickerBuilder()
        .addView(view)
        .setOAuthToken(token)
        .setDeveloperKey(process.env.REACT_APP_FIREBASE_AND_GOOGLE_API_KEY)
        .setCallback((data) => {
          if (data[window.google.picker.Response.ACTION] === window.google.picker.Action.PICKED) {
            const doc = data[window.google.picker.Response.DOCUMENTS][0];
            console.log('The user selected: ' + doc[window.google.picker.Document.ID]);
            onFolderPicked(doc[window.google.picker.Document.ID]);
  
            // List all the files in the picked folder
            window.gapi.client.drive.files.list({
              'q': `'${doc[window.google.picker.Document.ID]}' in parents`,
              'fields': 'files(id, name, webViewLink)'
            }).then(function(response) {
              const files = response.result.files;
              if (files && files.length > 0) {
                const urls = files.map(file => file.webViewLink);
                console.log('File URLs:', urls);
              } else {
                console.log('No files found.');
              }
            });
          }
        })
        .build();
      picker.setVisible(true);
    });
  };
  return (
    <button onClick={handlePickFolder}>Select Folder of Videos</button>
  );
}

export default FolderPicker;