// SignIn.js
import React from 'react';

const SignIn = ({ onSignIn }) => {
  return (
    <button onClick={onSignIn}>Sign in with Google</button>
  );
};

export default SignIn;
