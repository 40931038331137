// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { auth, googleProvider } from './firebaseConfig';
import { signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import SignIn from './components/SignIn';
import FolderPicker from './components/FolderPicker';
import PrivacyPolicy from './components/PrivacyPolicy';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [googleToken, setGoogleToken] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, currentUser => {
      setUser(currentUser);
      console.log(currentUser ? 'User is signed in' : 'User is signed out');
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("signInWithPopup result", result);
      setUser(result.user);
  
      // Retrieve the Google OAuth access token
      if (result._tokenResponse && result._tokenResponse.oauthAccessToken) {
        const accessToken = result._tokenResponse.oauthAccessToken;
        setGoogleToken(accessToken); // Store the access token in googleToken state
        console.log('Google OAuth access token:', accessToken);
      } else {
        console.log('No Google OAuth access token available');
      }
    } catch (error) {
      console.error('Error during sign in:', error.message);
    }
  };
  
  

  const handleSignOut = async () => {
    await signOut(auth);
    setUser(null);
    setGoogleToken(null);
  };

  const handleFolderPicked = (folderId) => {
    console.log('The user picked folder:', folderId);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <>
              <header className="App-header">
                <h1>My Video Hero</h1>
                {!user ? (
                  <SignIn onSignIn={handleSignIn} />
                ) : (
                  <>
                    <button onClick={handleSignOut}>Sign out</button>
                    <FolderPicker token={googleToken} onFolderPicked={handleFolderPicked} />
                  </>
                )}
              </header>
              <div>Welcome to My Video Hero</div>
            </>
          } />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );  
}

export default App;
