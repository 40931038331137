import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy for My Video Hero Web</h1>
      <p>Last Updated: February 27, 2024</p>

      <section>
        <h2>1. Introduction</h2>
        <p>Welcome to My Video Hero Web! We are committed to respecting your privacy and protecting your personally identifiable information. This Privacy Policy outlines how we collect, use, store, and disclose information about our users.</p>
      </section>

      <section>
        <h2>2. Information Collection and Use</h2>
        <p>We collect information from you when you use our app, including but not limited to:</p>
        <ul>
          <li>Your Google account details when you sign in through Google OAuth.</li>
          <li>Any content you create or upload within the app.</li>
          <li>Usage data which helps us understand how you use our app.</li>
        </ul>
        <p>This information is used to:</p>
        <ul>
          <li>Provide, maintain, and improve our services.</li>
          <li>Understand and analyze how you use our app for a better user experience.</li>
          <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the app.</li>
        </ul>
      </section>

      <section>
        <h2>3. Data Sharing and Disclosure</h2>
        <p>We may share your information with third parties in certain circumstances, including:</p>
        <ul>
          <li>With your consent.</li>
          <li>For legal reasons, if we believe in good faith that it's necessary to comply with a legal obligation.</li>
          <li>With service providers who assist us in providing our services.</li>
        </ul>
      </section>

      <section>
        <h2>4. Data Security</h2>
        <p>We take the security of your data seriously and implement reasonable measures to protect it. However, no method of transmission over the Internet or method of electronic storage is 100% secure.</p>
      </section>

      <section>
        <h2>5. Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
      </section>

      <section>
        <h2>6. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:sproutseedswithcode@gmail.com">sproutseedswithcode@gmail.com</a>.</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
